<template>
  <div>
    <t-modal v-model="deleteDialog">
      <div class="p-4">
        <h4 class="text-sm font-semibold text-gray-800">Delete a client</h4>
        <div class="mt-5">
          <p class="text-sm text-red-500">
            You are about to delete a client this action is not reversible, and
            you will lose the data of this client, click delete below to process
            with this action
          </p>
        </div>
        <div class="mt-6 flex items-end justify-end">
          <t-button
            @click="deleteAClient"
            class="
              border
              w-28
              focus:outline-none
              border-accent
              px-3
              py-1
              rounded
              text-accent
            "
            variant="error"
          >
            <span v-if="!deletingClient">Yes, delete</span>
            <spinner color="text-red-500" v-else></spinner>
          </t-button>
        </div>
      </div>
    </t-modal>
    <page-title>Edit Client</page-title>
    <page-subtitle>Edit a previously created client here</page-subtitle>
    <div v-if="loadingClientData" class="flex items-center justify-center">
      <spinner></spinner>
    </div>
    <div v-else class="mt-5">
      <div class="bg-white p-5 rounded">
        <div class="flex flex-col">
          <section-subtitle> Client's Picture </section-subtitle>
          <div class="flex items-center space-x-5">
            <img
              v-if="file !== null"
              :src="linkImage"
              class="rounded-full w-20 h-20"
            />
            <label
              v-else
              for="image"
              class="
                rounded-full
                w-20
                h-20
                bg-gray-50
                upload-image
                cursor-pointer
              "
            >
              <input
                @change="profileImageChange"
                type="file"
                class="w-0 h-0"
                name="image"
                id="image"
              />
            </label>
            <div class="content flex flex-col space-y-3">
              <label
                v-if="file === null"
                for="image"
                class="
                  cursor-pointer
                  bg-primary
                  px-2
                  py-1
                  text-white text-sm
                  rounded
                "
                >Select Image</label
              >
              <!-- <span
                @click="uploadImage"
                v-else
                class="cursor-pointer bg-primary px-2 py-1 text-white text-sm rounded"
                >Upload Image</span
              > -->
              <button
                @click="
                  () => {
                    file = null
                    linkImage = ''
                  }
                "
                class="
                  border border-accent
                  text-accent
                  px-2
                  py-1
                  text-xs
                  rounded
                "
              >
                Remove Image
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-white p-5 rounded">
        <section-subtitle> Enter Clients details </section-subtitle>
        <div class="flex flex-col">
          <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div class="form-control">
              <label
                class="text-xs font-semibold text-gray-800 mb-2 block"
                for="name"
                >First Name</label
              >
              <t-input
                aria-required
                required
                v-model="client.firstName"
                class="focus:outline-none"
                type="text"
                aria-autocomplete="no"
              ></t-input>
            </div>
            <div class="form-control">
              <label
                class="text-xs font-semibold text-gray-800 mb-2 block"
                for="name"
                >Last Name</label
              >
              <t-input
                aria-required
                required
                v-model="client.lastName"
                class="focus:outline-none"
                type="text"
                aria-autocomplete="no"
              ></t-input>
            </div>
            <div class="form-control">
              <label
                class="text-xs font-semibold text-gray-800 mb-2 block"
                for="name"
                >Email</label
              >
              <t-input
                aria-required
                required
                v-model="client.email"
                class="focus:outline-none"
                type="email"
                aria-autocomplete="no"
              ></t-input>
            </div>
            <div class="form-control">
              <label
                class="text-xs font-semibold text-gray-800 mb-2 block"
                for="name"
                >Applicant Full Name
                <span class="text-accent font-normal"
                  >(as shown in password)</span
                >
              </label>
              <t-input
                aria-required
                required
                v-model="client.fullName"
                class="focus:outline-none"
                type="text"
                aria-autocomplete="no"
              ></t-input>
            </div>
            <div class="form-control">
              <label
                class="text-xs font-semibold text-gray-800 mb-2 block"
                for="name"
                >Gender</label
              >
              <t-select
                :options="['Male', 'Female']"
                v-model="client.gender"
                class="focus:outline-none"
                type="text"
                aria-autocomplete="no"
              ></t-select>
            </div>
            <div class="grid grid-cols-2 gap-4">
              <div class="form-control">
                <label
                  class="text-xs font-semibold text-gray-800 mb-2 block"
                  for="name"
                  >Place of Birth</label
                >
                <t-input
                  aria-required
                  required
                  v-model="client.placeOfBirth"
                  class="focus:outline-none"
                  type="text"
                  aria-autocomplete="no"
                ></t-input>
              </div>
              <div class="form-control">
                <label
                  class="text-xs font-semibold text-gray-800 mb-2 block"
                  for="name"
                  >Date of Birth</label
                >
                <t-input
                  aria-required
                  required
                  v-model="client.dateOfBirth"
                  class="focus:outline-none"
                  type="date"
                  aria-autocomplete="no"
                ></t-input>
              </div>
            </div>
            <div class="form-control">
              <label
                class="text-xs font-semibold text-gray-800 mb-2 block"
                for="name"
                >Country of Citizenship</label
              >
              <t-rich-select
                :options="counties"
                placeholder="Select a country"
                value-attribute="name"
                text-attribute="name"
                required
                v-model="client.countryOfCitizenship"
              >
              </t-rich-select>
              <!-- <v-select
                :options="counties"
                label="name"
                :reduce="(c) => c.name"
                required
                v-model="client.countryOfCitizenship"
              >
              </v-select> -->
            </div>
            <div class="form-control">
              <label
                class="text-xs font-semibold text-gray-800 mb-2 block"
                for="name"
                >Marital Status</label
              >
              <t-select
                aria-required
                required
                v-model="client.maritalStatus"
                class="focus:outline-none"
                :options="martialStatus"
              ></t-select>
            </div>
            <div class="form-control">
              <label
                class="text-xs font-semibold text-gray-800 mb-2 block"
                for="name"
                >Education
                <span class="text-accent font-normal"
                  >(client's highest level of Education)</span
                >
              </label>
              <t-select
                aria-required
                required
                v-model="client.highestEducationLevel"
                class="focus:outline-none"
                :options="educationLevels"
              ></t-select>
            </div>
            <div class="form-control">
              <label
                class="text-xs font-semibold text-gray-800 mb-2 block"
                for="name"
                >Current Occupation</label
              >
              <t-input
                aria-required
                required
                v-model="client.occupation"
                class="focus:outline-none"
                type="text"
                aria-autocomplete="no"
              ></t-input>
            </div>
            <div class="form-control">
              <label
                class="text-xs font-semibold text-gray-800 mb-2 block"
                for="name"
                >Phone Number</label
              >
              <t-input
                aria-required
                required
                v-model="client.phone"
                class="focus:outline-none"
                type="text"
                aria-autocomplete="no"
              ></t-input>
            </div>
            <div class="form-control">
              <label
                class="text-xs font-semibold text-gray-800 mb-2 block"
                for="name"
                >Residential Address</label
              >
              <t-textarea
                v-model="client.address"
                class="focus:outline-none"
                type="text"
                aria-autocomplete="no"
              ></t-textarea>
            </div>
            <div class="form-control">
              <label
                class="text-xs font-semibold text-gray-800 mb-2 block"
                for="name"
                >Mailing Address</label
              >
              <t-textarea
                v-model="client.mailingAddress"
                class="focus:outline-none"
                type="text"
                aria-autocomplete="no"
              ></t-textarea>
            </div>
            <div class="form-control">
              <label
                class="text-xs font-semibold text-gray-800 mb-2 block"
                for="name"
                >Country of Interest</label
              >
              <t-rich-select
                :options="counties"
                placeholder="Select a country"
                value-attribute="name"
                text-attribute="name"
                required
                v-model="client.countryOfInterest"
              >
              </t-rich-select>
              <!-- <v-select
                :options="counties"
                label="name"
                :reduce="(c) => c.name"
                required
                v-model="client.countryOfInterest"
              >
              </v-select> -->
            </div>
            <div class="form-control">
              <label
                class="text-xs font-semibold text-gray-800 mb-2 block"
                for="name"
                >Purpose of Travelling</label
              >
              <t-textarea
                v-model="client.purposeOfTraveling"
                class="focus:outline-none"
                type="text"
                aria-autocomplete="no"
              ></t-textarea>
            </div>
            <div class="form-control">
              <label
                class="text-xs font-semibold text-gray-800 mb-2 block"
                for="name"
                >Form Pickup Location</label
              >
              <t-textarea
                v-model="client.pickupLocation"
                class="focus:outline-none"
                type="text"
                aria-autocomplete="no"
              ></t-textarea>
            </div>
          </div>
          <div class="flex space-x-5 items-center justify-end mt-10">
            <t-button
              :to="{ name: 'Client' }"
              class="
                border
                w-28
                focus:outline-none
                border-accent
                px-3
                py-1
                rounded
                text-accent
              "
              variant="error"
            >
              Cancel
            </t-button>
            <t-button
              @click="deleteDialog = true"
              class="
                border
                w-28
                focus:outline-none
                border-accent
                px-3
                py-1
                rounded
                text-accent
              "
              variant="error"
            >
              Delete
            </t-button>
            <t-button
              @click="updateClient"
              class="w-28 flex items-start justify-center space-x-5"
            >
              <span>Save</span> <spinner v-if="loading"></spinner>
            </t-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageSubtitle from '../../components/Typography/PageSubtitle.vue'
import PageTitle from '../../components/Typography/PageTitle.vue'
import SectionSubtitle from '../../components/Typography/SectionSubtitle.vue'
import clientApi from '../../api/client/index'
import Spinner from '../../components/Utils/Spinner.vue'
import counties from '../../data/countries.json'
import { educationLevels, martialStatus } from '../../utils/helpers'

export default {
  name: 'EditClient',
  components: { PageTitle, PageSubtitle, SectionSubtitle, Spinner },
  data: () => ({
    martialStatus,
    educationLevels,
    client: {},
    clientId: '',
    deleteDialog: false,
    deletingClient: false,
    loading: false,
    counties: counties,
    linkImage: '',
    file: null,
    loadingClientData: false
  }),
  created() {
    this.clientId = this.$route.params.id
    this.getClient()
  },
  methods: {
    async getClient() {
      this.loadingClientData = true
      const res = await clientApi
        .client()
        .getAClient({ clientId: this.clientId })
      this.client = res.data
      this.loadingClientData = false
    },
    profileImageChange(e) {
      const file = e.target.files[0]
      this.file = file
      this.linkImage = URL.createObjectURL(file)
    },
    async updateClient() {
      this.loading = true
      const res = await clientApi
        .client()
        .updateClient(
          { clientId: this.clientId },
          { ...this.client, image: this.file }
        )
      if (res.error) {
        this.$store.commit('alert', {
          error: true,
          status: true,
          message: res.errorMessage
        })
        this.loading = false
        return
      }
      this.$store.commit('alert', {
        error: false,
        status: true,
        message: res.message
      })
      this.loading = false
      this.$router.push({ name: 'Client' })
    },
    async deleteAClient() {
      this.deletingClient = true
      const res = await clientApi
        .client()
        .deleteAUser({ clientId: this.clientId })
      if (res.error) {
        this.$store.commit('alert', {
          error: true,
          status: true,
          message: res.errorMessage
        })
        this.deletingClient = false
        return
      }
      this.$store.commit('alert', {
        error: false,
        status: true,
        message: res.message
      })
      this.deletingClient = false
      this.$router.push({ name: 'Client' })
    }
  }
}
</script>

<style>
.upload-image {
  background-image: url('../../assets/svgs/no_image.svg');
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
