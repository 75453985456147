<template>
  <div>
    <page-title>Clients</page-title>
    <page-subtitle>Here's the clients list</page-subtitle>
    <div class="mt-5">
      <div class="bg-white p-3">
        <div class="md:flex rounded md:space-x-2 items-center justify-between">
          <div
            class="search-container border focus-within:ring-2 ring-primary border-gray-100 rounded pl-4 flex items-center w-full"
          >
            <img
              class="w-5 h-5"
              src="../../assets/svgs/search.svg"
              alt="search"
            />
            <input
              v-model="search"
              class="border-none shadow-none bg-transparent w-full focus:outline-none focus:border-none focus:ring-0"
              type="search"
              placeholder="Search by last name..."
            />
          </div>
          <t-button
            class="text-sm block w-full mt-2 md:mt-0 md:w-44 py-2"
            @click="exportData"
            >Export Data</t-button
          >
          <t-button
            :to="{ name: 'AddClient' }"
            class="text-sm block w-full mt-2 md:mt-0 md:w-44 py-2"
            >Add new Client</t-button
          >
        </div>
        <div v-if="loading" class="flex items-center justify-center mt-5">
          <spinner color="text-primary" />
        </div>
        <div v-else class="client-list grid grid-cols-1 mt-5">
          <div class="flex flex-col w-full">
            <div class="overflow-x-auto">
              <table class="table-auto min-h-full min-w-full">
                <thead
                  class="bg-primary-faded border-b-2 border-primary text-left font-semibold text-sm text-black"
                >
                  <tr>
                    <th class="py-3 px-3">Name</th>
                    <th class="py-3 px-3">Email</th>
                    <th class="py-3 px-3">Phone</th>
                    <th class="py-3 px-3">Residential Address</th>
                    <!-- <th class="py-3 px-3">Status</th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="client in clients"
                    @click="
                      () => {
                        $router.push({
                          name: 'ClientProfile',
                          params: { id: client._id }
                        })
                      }
                    "
                    :key="client._id"
                    class="text-sm cursor-pointer hover:border-l-2 hover:border-primary hover:bg-primary-faded"
                  >
                    <td class="px-3 py-4">
                      {{ client.lastName }} {{ client.firstName }}
                    </td>
                    <td class="px-3 py-4">{{ client.email }}</td>
                    <td class="px-3 py-4">{{ client.phone }}</td>
                    <td class="px-3 py-4">
                      {{ client.address }}
                    </td>
                    <!-- <td class="px-3 py-4">...</td> -->
                  </tr>
                </tbody>
                <p class="mt-5 text-sm">
                  Showing
                  <b class="text-primary"
                    >{{ meta.offset }} - {{ meta.limit + meta.offset }}</b
                  >
                  of
                  <b class="text-primary">{{ meta.total }}</b>
                </p>
              </table>
            </div>
          </div>
        </div>
        <div v-if="!loading && !searching" class="mt-10 flex flex-wrap">
          <span
            v-for="(l, index) in meta.pagination"
            :class="
              `p-2 ${
                meta.offset / index === meta.limit
                  ? 'text-white bg-primary'
                  : 'text-black'
              } py-1 cursor-pointer rounded shadow m-2`
            "
            @click="getAllClients(index * meta.limit, index)"
            :key="l"
          >
            {{ l }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageSubtitle from '../../components/Typography/PageSubtitle.vue'
import PageTitle from '../../components/Typography/PageTitle.vue'
import Spinner from '../../components/Utils/Spinner'
import clientApi from '../../api/client'
import _ from 'lodash'

export default {
  name: 'Clients',
  components: { PageTitle, PageSubtitle, Spinner },
  data() {
    return {
      clients: [],
      meta: {},
      search: '',
      limit: 50,
      loading: false,
      searching: false
    }
  },
  created() {
    this.getAllClients()
    this.debouncedGetAnswer = _.debounce(this.searchClient, 500)
  },
  computed: {
    dataList() {
      if (this.clients.length === 0) return []
      return this.clients.filter(c =>
        this.search
          .toLowerCase()
          .toString()
          .split(' ')
          .every(v => {
            const data = c.firstName + ' ' + c.lastName + ' ' + c.email
            return data.toLowerCase().includes(v)
          })
      )
    }
  },
  watch: {
    search(newValue) {
      if (newValue === '') {
        this.getAllClients()
        return
      }
      this.debouncedGetAnswer()
    }
  },
  methods: {
    async searchClient() {
      this.searching = true
      this.loading = true
      const res = await clientApi
        .client()
        .getAll({ offset: 0, limit: 0 }, true, {
          searchBy: 'lastName',
          keyword: this.search
        })
      this.clients = res.data.data.sort((a, b) => {
        const test1 = a.lastName?.toLowerCase()
        const test2 = b.lastName?.toLowerCase()
        if (test1 < test2) return -1
        if (test1 > test2) return 1
        return 0
      })
      this.meta = res.data.meta
      this.meta.pages =
        Math.ceil(this.meta.total / this.meta.limit) === Infinity
          ? 0
          : Math.ceil(this.meta.total / this.meta.limit)
      this.loading = false
    },
    async getAllClients(offset, l = 1) {
      this.searching = false
      this.loading = true
      const res = await clientApi.client().getAll({ offset, limit: this.limit })
      this.clients = res.data.data.sort((a, b) => {
        const test1 = a.lastName?.toLowerCase()
        const test2 = b.lastName?.toLowerCase()
        if (test1 < test2) return -1
        if (test1 > test2) return 1
        return 0
      })
      this.meta = res.data.meta
      this.meta.pages =
        Math.ceil(this.meta.total / this.meta.limit) === Infinity
          ? 0
          : Math.ceil(this.meta.total / this.meta.limit)

      this.generatePaginationPages(this.meta.pages, l)
      this.loading = false
    },
    async generatePaginationPages(pages, activePage) {
      const returnPage = []
      const firstPart = 10
      const lastPart = pages - 10
      for (let i = 0; i < pages; i++) {
        if (i < firstPart) {
          returnPage.push(i + 1)
        } else if (i === activePage) {
          returnPage.push(i + 1)
        } else if (i === activePage - 1) {
          returnPage.push(i + 1)
        } else if (i === activePage + 1) {
          returnPage.push(i + 1)
        } else if (i === activePage - 2) {
          returnPage.push(i + 1)
        } else if (i === activePage + 2) {
          returnPage.push(i + 1)
        } else if (i === activePage - 3) {
          returnPage.push(i + 1)
        } else if (i === activePage + 3) {
          returnPage.push(i + 1)
        } else if (i === activePage - 4) {
          returnPage.push(i + 1)
        } else if (i === activePage + 4) {
          returnPage.push(i + 1)
        } else if (i > lastPart) {
          returnPage.push(i + 1)
        } else {
          returnPage.push('...')
        }
      }
      const unique = [...new Set(returnPage)]
      this.meta.pagination = unique
    },
    async exportData() {
      this.loading = true
      const res = await clientApi.client().exportClients()
      this.loading = false
      if (res.error) {
        this.$store.commit('alert', {
          error: true,
          message: res.errorMessage,
          status: true
        })
        return
      }

      const base64CSVString = res.data.csv
      const link = document.createElement('a')
      link.href = `data:text/csv;base64,${base64CSVString}`
      link.download = 'clients.csv'
      link.click()

      this.$store.commit('alert', {
        error: false,
        message: res.message,
        status: true
      })
    }
  }
}
</script>

<style></style>
