var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('page-title',[_vm._v("Clients")]),_c('page-subtitle',[_vm._v("Here's the clients list")]),_c('div',{staticClass:"mt-5"},[_c('div',{staticClass:"bg-white p-3"},[_c('div',{staticClass:"md:flex rounded md:space-x-2 items-center justify-between"},[_c('div',{staticClass:"search-container border focus-within:ring-2 ring-primary border-gray-100 rounded pl-4 flex items-center w-full"},[_c('img',{staticClass:"w-5 h-5",attrs:{"src":require("../../assets/svgs/search.svg"),"alt":"search"}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],staticClass:"border-none shadow-none bg-transparent w-full focus:outline-none focus:border-none focus:ring-0",attrs:{"type":"search","placeholder":"Search by last name..."},domProps:{"value":(_vm.search)},on:{"input":function($event){if($event.target.composing){ return; }_vm.search=$event.target.value}}})]),_c('t-button',{staticClass:"text-sm block w-full mt-2 md:mt-0 md:w-44 py-2",on:{"click":_vm.exportData}},[_vm._v("Export Data")]),_c('t-button',{staticClass:"text-sm block w-full mt-2 md:mt-0 md:w-44 py-2",attrs:{"to":{ name: 'AddClient' }}},[_vm._v("Add new Client")])],1),(_vm.loading)?_c('div',{staticClass:"flex items-center justify-center mt-5"},[_c('spinner',{attrs:{"color":"text-primary"}})],1):_c('div',{staticClass:"client-list grid grid-cols-1 mt-5"},[_c('div',{staticClass:"flex flex-col w-full"},[_c('div',{staticClass:"overflow-x-auto"},[_c('table',{staticClass:"table-auto min-h-full min-w-full"},[_vm._m(0),_c('tbody',_vm._l((_vm.clients),function(client){return _c('tr',{key:client._id,staticClass:"text-sm cursor-pointer hover:border-l-2 hover:border-primary hover:bg-primary-faded",on:{"click":function () {
                      _vm.$router.push({
                        name: 'ClientProfile',
                        params: { id: client._id }
                      })
                    }}},[_c('td',{staticClass:"px-3 py-4"},[_vm._v(" "+_vm._s(client.lastName)+" "+_vm._s(client.firstName)+" ")]),_c('td',{staticClass:"px-3 py-4"},[_vm._v(_vm._s(client.email))]),_c('td',{staticClass:"px-3 py-4"},[_vm._v(_vm._s(client.phone))]),_c('td',{staticClass:"px-3 py-4"},[_vm._v(" "+_vm._s(client.address)+" ")])])}),0),_c('p',{staticClass:"mt-5 text-sm"},[_vm._v(" Showing "),_c('b',{staticClass:"text-primary"},[_vm._v(_vm._s(_vm.meta.offset)+" - "+_vm._s(_vm.meta.limit + _vm.meta.offset))]),_vm._v(" of "),_c('b',{staticClass:"text-primary"},[_vm._v(_vm._s(_vm.meta.total))])])])])])]),(!_vm.loading && !_vm.searching)?_c('div',{staticClass:"mt-10 flex flex-wrap"},_vm._l((_vm.meta.pagination),function(l,index){return _c('span',{key:l,class:("p-2 " + (_vm.meta.offset / index === _vm.meta.limit
                ? 'text-white bg-primary'
                : 'text-black') + " py-1 cursor-pointer rounded shadow m-2"),on:{"click":function($event){return _vm.getAllClients(index * _vm.meta.limit, index)}}},[_vm._v(" "+_vm._s(l)+" ")])}),0):_vm._e()])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',{staticClass:"bg-primary-faded border-b-2 border-primary text-left font-semibold text-sm text-black"},[_c('tr',[_c('th',{staticClass:"py-3 px-3"},[_vm._v("Name")]),_c('th',{staticClass:"py-3 px-3"},[_vm._v("Email")]),_c('th',{staticClass:"py-3 px-3"},[_vm._v("Phone")]),_c('th',{staticClass:"py-3 px-3"},[_vm._v("Residential Address")])])])}]

export { render, staticRenderFns }