<template>
  <div>
    <page-title>Your Profile</page-title>
    <page-subtitle>Update your password here</page-subtitle>
    <div class="mt-5">
      <div class="bg-white p-5 rounded">
        <section-subtitle> Update password </section-subtitle>
        <div class="flex flex-col">
          <form class="grid grid-cols-1 md:grid-cols-2 gap-5">
            <div class="form-control">
              <label
                class="text-xs font-semibold text-gray-800 mb-2 block"
                for="name"
                >Old Password</label
              >
              <t-input
                v-model="app.oldPassword"
                class="focus:outline-none"
                type="text"
                aria-autocomplete="no"
              ></t-input>
            </div>
            <div class="form-control">
              <label
                class="text-xs font-semibold text-gray-800 mb-2 block"
                for="name"
                >New Password</label
              >
              <t-input
                v-model="app.password"
                class="focus:outline-none"
                type="text"
                aria-autocomplete="no"
              ></t-input>
            </div>
            <div class="form-control">
              <label
                class="text-xs font-semibold text-gray-800 mb-2 block"
                for="name"
                >Confirm New Password</label
              >
              <t-input
                v-model="app.confirmPassword"
                class="focus:outline-none"
                type="text"
                aria-autocomplete="no"
              ></t-input>
            </div>
          </form>
          <div class="flex space-x-5 items-center justify-end mt-5">
            <t-button
              @click="$router.go(-1)"
              class="border w-28 border-accent px-3 py-1 rounded text-accent"
              variant="error"
            >
              Cancel
            </t-button>
            <t-button
              @click="changePassword"
              class="w-28 flex items-center justify-center space-x-4"
            >
              <span>Save</span> <spinner v-if="loading"></spinner>
            </t-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import auth from '../../api/auth'
import PageSubtitle from '../../components/Typography/PageSubtitle.vue'
import PageTitle from '../../components/Typography/PageTitle.vue'
import SectionSubtitle from '../../components/Typography/SectionSubtitle'
import Spinner from '../../components/Utils/Spinner'

export default {
  name: 'UserProfile',
  components: { PageTitle, PageSubtitle, SectionSubtitle, Spinner },
  data: () => ({
    app: {
      oldPassword: '',
      password: '',
      confirmPassword: ''
    },
    loading: false
  }),
  created() {},
  methods: {
    async changePassword() {
      if (this.app.oldPassword === '') {
        this.$store.commit('alert', {
          error: true,
          status: true,
          message: 'Old password cannot be empty'
        })
        return
      }
      if (this.app.password === '' || !(this.app.password.length > 8)) {
        this.$store.commit('alert', {
          error: true,
          status: true,
          message: 'Password must be 8 chars and above'
        })
        return
      }
      if (this.app.password !== this.app.confirmPassword) {
        this.$store.commit('alert', {
          error: true,
          status: true,
          message: "Password don't match"
        })
        return
      }
      this.loading = true
      const res = await auth.auth().changePassword({ ...this.app })
      if (res.error) {
        this.$store.commit('alert', {
          error: true,
          status: true,
          message: res.errorMessage
        })
        this.loading = false
        return
      }
      this.loading = false
      this.$store.commit('alert', {
        error: false,
        status: true,
        message: res.message
      })
      this.app = {
        oldPassword: '',
        password: '',
        confirmPassword: ''
      }
    }
  }
}
</script>

<style></style>
